<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认合并" width="520px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div class="students-qrcode">
            <!-- <div style="font-weight: bold;font-size:16px">使用微信扫一扫即可自动绑定学员账号</div>
            <div>绑定后家长可在公众号中使用课表、家校互通等功能</div> -->
            <div>

                <div class="qrcode-tips">请注视镜头并保持一段时间。</div>
                    <div id="video-background" class="bk-radius">
                    <video v-show="true" id="video" class="video-camera" preload autoplay loop muted />
                    <canvas id="overlay"></canvas>
                </div>
                <!-- <a-upload name="file" 
                    multiple
                    accept='.png,.jpeg,.jpg'
                    :showUploadList="false"
                    :data="uploadParams"
                    :action="uploadUrl" 
                    :beforeUpload="beforeUpload"
                    @change="changeFile">
                    <a-button type="primary" icon="plus">上传图片</a-button>
                </a-upload>
                <a-button type="dashed" icon="close" @click="cleanFace">清除采集</a-button> -->
                <a-button type="dashed" shape="round" icon="close" round @click="cleanFace">清除采集</a-button>
            </div>
            
        </div>
        <div class="students-student">
            <div>已采集人脸（{{face_list.length}}）</div>
            <div class="students-student-list">
                <div v-for="(item,index) in face_list" :key="index" class="students-student-item">
                    <div class="students-student-item-img">
                        <img :src="item.face_path" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div style="position:absolute;top:10000px;">
            <canvas v-show="flagCanvas === true" id="canvas1" width="200" height="200"/>
        </div>
  </a-modal>
</template>

<script>
    import moment from 'moment'
    import url from '@/utils/URL'
    import createFilePath from '@/utils/tools'
    require("tracking/build/tracking-min.js");
    require("tracking/build/data/face-min.js");
    import dat from "dat.gui";
    import * as faceapi from "face-api.js";
    export default {
        name:"faceModal",
        inject: ['parent'],
        data() {
            return {
                modalTitle: '',
                visible: false,
                loading: false,
                qrcode_url: '',
                uploadUrl: url.uploadQiNiu, 
                uploadParams: {},
                confirmLoading: false,
                face_list: [],
            }
        },
        props:{
            item: Object
        },
        created () {
            this.visible = true
            this.modalTitle = `人脸采集-${this.item.employee_name}`
            this.getFace()
            this.resetTracking()
        },
        methods: {
            moment,
            async resetTracking() {
                const _this = this;
                this.flag = true;
                this.flagCanvas = false;
                this.flagCanvas2 = false;
                this.base64Str = "";
                this.trackerTask = "";
                this.tracker = {};
                this.tracker2 = {};
                this.mediaStreamTrack = {};
                this.video = {};
                this.count = 0;
                this.x = 0;
                this.y = 0;
                this.width = 0;
                this.height = 0;
                
                this.$nextTick(()=>{
                    this.initTracking();
                })
            },
            // 初始化人脸追踪
            async initTracking() {
            //
            const videoBackground = document.getElementById("video-background");
            if(!videoBackground)
                return ;
            videoBackground.style.backgroundImage = "";
            const _this = this;
            const video = (this.video = document.getElementById("video"));
            var canvas = document.getElementById("overlay");
            var context = canvas.getContext("2d");

            _this.tracker = new window.tracking.ObjectTracker("face");
            _this.tracker.setInitialScale(4);
            _this.tracker.setStepSize(2);
            // 转头角度影响识别率
            _this.tracker.setEdgesDensity(0.1);

            _this.openCamera();
            _this.trackerTask = window.tracking.track("#video", _this.tracker);

            // 使用期约来识别人脸，识别有就提示用户保持静止，3秒后拍照
            let prom = new Promise((resolve, reject) => {
                setTimeout(() => {
                _this.tracker.on("track", function (event) {
                    if (event.data.length) {
                    // 会不停的去检测人脸，所以这里需要做个锁
                    if (_this.flag) {
                        _this.flag = false;
                        _this.$message.success('已识别人脸，请注视镜头并保持一段时间');
                        _this.base64Str = "";
                        setTimeout(() => {
                        _this.flagCanvas = true;
                        const canvasUpload = document.getElementById("canvas1");
                        if(!canvasUpload)
                            return ;
                        const contextUpload = canvasUpload.getContext("2d");
                        contextUpload.clearRect(0, 0, canvasUpload.width, canvasUpload.height);
                        contextUpload.drawImage(video, 90, 30, 400, 400, 0, 0, 200, 200);
                        _this.base64Str = canvasUpload.toDataURL("image/jpeg");
                        _this.video.pause();
                        if (_this.base64Str) {
                            //console.log(_this.base64Str);
                            _this.$message.success('捕获人脸成功');
                            // _this.face_list = [{'face_path':_this.base64Str}];
                            _this.setFace(_this.base64Str)
                            //console.log(_this.toFile(_this.base64Str))

                        } else {
                            _this.$message.warning('未捕获人脸，请重新拍照'); 
                        }
                        // 关闭摄像头
                        // _this.closeCamera();
                        // 直接获取 base64 不显示画布可开启
                        // _this.flag = true;
                        }, 2500);
                    }
                    }
                });
                resolve();
                }, 0);
            });
            prom.then(() => {
                setTimeout(() => {
                _this.resetTracking();
                }, 9000);
            });
            // await sleep(2000);
            },
            openCamera() {

            if(!this.parent.isFaceModal)
                return true;

            console.log('open');
            if (navigator.mediaDevices === undefined) {
                navigator.mediaDevices = {};
            }
            if (navigator.mediaDevices.getUserMedia === undefined) {
                navigator.mediaDevices.getUserMedia = function (constraints) {
                // 首先，如果有getUserMedia的话，就获得它
                const getUserMedia =
                    navigator.getUserMedia ||
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.msGetUserMedia;
                // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
                if (!getUserMedia) {
                    return Promise.reject(new Error("getUserMedia 浏览器不支持摄像头"));
                }
                // 否则，为老的navigator.getUserMedia方法包裹一个Promise
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
                };
            }
            const constraints = {
                video: true,
                audio: false,
            };
            const promise = navigator.mediaDevices.getUserMedia(constraints);
            promise
                .then((stream) => {
                this.mediaStreamTrack = stream.getTracks()[0];
                window.stream = stream;
                const video = document.getElementById("video");
                // 旧的浏览器可能没有srcObject
                if ("srcObject" in video) {
                    video.srcObject = stream;
                } else {
                    video.src = window.URL.createObjectURL(stream);
                }
                video.onloadedmetadata = function (e) {
                    video.play();
                };
                })
                .catch((err) => {
                console.error(err.name + ": " + err.message);
                console.error("获取权限失败");
                });
            },
            closeCamera() {
                console.log('close');
                if (typeof window.stream === "object") {
                    if ("srcObject" in this.video) {
                    this.video.srcObject = null;
                    }
                    window.stream.getTracks().forEach((track) => track.stop());
                }
            },
            toFile(ndata) {
                // ndata为base64格式地址
                let arr = ndata.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], this.fileName, {type:mime})
            },
            async setFace (base64Str) {
                if(this.face_list.length>=5){
                    this.$message.warning('人脸采集最多5张，请清除采集。'); 
                    return false;
                }
                // console.log(base64Str);
                // console.log(this.item.student_id);
                // let res = await this.$store.dispatch('studentsSetFaceAction',{'student_id':this.item.student_id,'face_base64':base64Str})
                try {
                    let res = await this.$store.dispatch('employeeAccountSetFaceAction',{data: {'employee_id':this.item.employee_id,'face_base64':base64Str}})
                    if(res.status=='success'){    
                        this.closeCamera();
                        this.parent.isFaceModal=false;
                        // this.getFace();
                        this.$message.success('人脸采集成功。');
                        // this.parent.getList()
                    }else{
                        this.$message.error('未匹配到相符人脸。'); 
                    }
                }catch{
                }      
            },
            async getFace(){
                this.loading = true
                await this.$store.dispatch('employeeAccountFaceAction',{data:{employee_id:this.item.employee_id}})
                .then(res=>{
                    this.face_list=res.data.face_list;
                    this.loading = false
                })
            },
            async cleanFace(){
                this.loading = true
                
                    await this.$store.dispatch('employeeAccountCleanFaceAction',{data:{employee_id:this.item.employee_id}})
                    .then(res=>{
                        this.face_list=[];
                        this.loading = false
                    })
                
            },
            async beforeUpload(file) {
                if (!file) { return false }
                if(!this.uploadParams.token){
                    let res = await this.$store.dispatch('imageTokenAction', {})
                    if (res) {
                        this.uploadParams.token = res.data.uptoken
                        this.uploadParams.key = createFilePath(file.name)
                        return true
                    }
                    return false
                }
            },
            async changeFile(file) {
                this.loading = true
                if (file.file && file.file.status === 'done') {
                const data = {
                    employee_id:this.item.employee_id,
                    face_path:file.file.response.key,
                }
                try {
                    let res = await this.$store.dispatch('employeeAccountSetFaceAction', { data })
                    if (res) {
                        this.getFace()
                    }
                } catch (error) {
                    this.loading=false;
                    console.log(error)
                }
            }
            },
            handleOk() {},
            handleCancel(){
                this.parent.isFaceModal=false;
                this.closeCamera();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .students-qrcode{
        text-align:center;
        div{
            margin-bottom: 10px;
        }
    }
    .students-student{
        padding-top: 10px;
        border-top: 1px solid #eee;
        &-list{
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
        }
        &-item{
            display: inline-block;
            margin-right: 7px;
            &-img{
                width: 60px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                img{
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                }
            }
            div{
                width: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
<style scoped>
.bk-radius {
  width: 200px;
  height: 200px;
  overflow: hidden;
  /* background-color: #42b983; */
  background-image: url("../../../../assets/face.png");
  z-index: -1;
  /* opacity: 0.5; */
  border-radius: 20%;
  margin: auto;
}

.rect2 {
  border: 2px solid #a64ceb;
  left: -1000px;
  position: absolute;
  top: -1000px;
}
.video-camera {
  width: 300px;
  height: 300px;
  margin-top: -40px;
  margin-left: -40px;

  /* position: absolute; */
}

.custom-block.tip {
  background-color: #ecf8ff;
  border-color: #50bfff;
  color: #409eff;
}

.custom-block,
.custom-block.tip,
.custom-block {
  padding: 0.1rem 1.5rem;
  border-left-width: 0.5rem;
  border-left-style: solid;
  margin: 1rem 0;
}
</style>
