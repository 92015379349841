<template>
  <div class="common">
    <div class="c-side" style="width: 360px; padding-right: 10px;">
      <div class="buttons" style="font-size: 16px; line-height: 75px; border-bottom: 1px solid #f1f1f1;">
        <div>部门结构</div>
      </div>
      <div v-if="canIndex" class="list commonTree">
        <vue-element-loading :active="loadingP" color="#00cca2" spinner="spinner"/>
        <tree v-if="reFresh" :data='treeData' :options="treeOptions" ref="sourceTree">
            <div slot-scope="{ node }" class="node-container">
                <div class="node-text">{{ node.text }}</div>
                <!-- <div class="node-text">({{node.data.department_count}})</div> -->
                <div @click.stop class="node-controls">
                    <a-icon style='fontSize:20px' type="form" @mouseup.stop="editNode(node)"/>
                    <a-icon v-if="node.depth != 0" style='fontSize:20px' type="close-square" @mouseup.stop="removeNode(node)"/>
                    <a-icon style='fontSize:20px' type="plus-square" @mouseup.stop="addChildNode(node)"/>
                </div>
            </div>
        </tree>
      </div>
    </div>
    <div class="c-main">
      <h3 style="text-align:center">{{agreementName}}</h3>
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增员工</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' v-if="selectedRowKeys.length == 0" @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>员工姓名/手机号</span>
                </template>
                <a-input allowClear v-model.trim="searchData.realname" placeholder="员工姓名/手机号/工号" style="width: 200px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            
            <a-form-item>
                <a-popover  placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                    <div class="more-search">
                    <a-form layout='inline'>
                        <a-form-item label="员工性别" class="block-line" >
                          <a-select v-model="searchParams.search.gender" allowClear placeholder="请选择">
                            <a-select-option v-for="(item, index) of sexs" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item class="block-line" label="是否兼职">
                            <a-select v-model='searchParams.search.part_time' allowClear placeholder="是否兼职">
                            <a-select-option :value="1">是</a-select-option>
                            <a-select-option :value="0">否</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="直属上级" class="block-line" >
                          <a-select v-model="searchParams.search.parent_id" allowClear placeholder="请选择" @search="handleSearch" showSearch :filterOption="filterOption">
                            <a-select-option v-for="(item,index) in staffList" :key="index"  :value="item.employee_id">{{item.filter_name}}</a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item class="block-line" label="账户角色">
                            <a-tree-select
                            dropdownClassName='followTreeSelect'
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            placeholder="账号角色"
                            allow-clear
                            treeNodeFilterProp="title"
                            v-model='searchParams.search.group_id'
                            :show-checked-strategy="SHOW_PARENT"
                            search-placeholder="Please select">
                              <a-tree-select-node v-for="(d, index) of roles" :disabled="true" :key="d.type_id" :value="d.type_id" :title="d.name">
                                <a-tree-select-node v-for="(dc, ind) of d.children" :key="dc.value" :value="dc.value" :title="dc.title"></a-tree-select-node>
                              </a-tree-select-node>
                            </a-tree-select>
                        </a-form-item>
                        <a-form-item class="block-line" label="账户状态">
                            <a-select v-model='searchParams.search.status' allowClear placeholder="状态">
                            <a-select-option value="">全部</a-select-option>
                            <a-select-option :value="1">正常</a-select-option>
                            <a-select-option :value="0">禁用中</a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item label="员工职称" class="block-line" >
                          <a-select v-model="searchParams.search.position_id" allowClear placeholder="请选择" showSearch :filterOption="filterOption">
                            <a-select-option v-for="(item,index) in positionList" :key="index"  :value="item.position_id">{{item.filter_name}}</a-select-option>
                          </a-select>
                        </a-form-item>

                        <a-form-item class="block-line" label="添加时间">
                            <a-range-picker
                              :ranges="rangesData"
                              v-model='searchDatas.add'
                              allowClear
                              @change="(val,time)=>handleTime(val,time,'add')" />
                        </a-form-item>

                    </a-form>
                    <div class="more-search__buttons">
                        <a-button type="dashed" @click="closeSearch">取消</a-button>
                        <a-button @click="reset">重置</a-button>
                        <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                    </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
                </a-popover>
            </a-form-item>
          </a-form>
          <a-form layout='inline' v-else>
              <a-form-item>
                  已选择<span>{{selectedRowKeys.length}}</span>项
              </a-form-item>
              <a-form-item>
                  <a-divider type="vertical" />
              </a-form-item>
              <a-form-item>
                  <a-button @click="showTransfer" icon="apartment">移动部门</a-button>
              </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
          :selection="{key: 'employee_id'}"
          :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
          :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>

          <template slot="employee_name" slot-scope="text,record">
            {{text}}
          </template>
          <template slot="wechat" slot-scope="text, record">
            <svg style="width: 12px;height: 12px;margin-left: 5px;" aria-hidden="true">
              <use v-show="record.employee_wechat == 1" xlink:href="#icon-weixin"></use>
              <use v-show="record.employee_wechat == 0" xlink:href="#icon-weixin1"></use>
            </svg>
          </template>
          <template slot="status" slot-scope="text,record">
            <a-switch checkedChildren="启" unCheckedChildren="禁" :checked="text === 1" @change="changeStatus(record)"/>
          </template>

          <template slot="image" slot-scope="text, record">
            <LImg :src="text" style="width: 28px; height: 28px;"/>
          </template>

          <template slot="department" slot-scope="text,record">
            <div>
              <div v-for="(it,index) in record.department_name" :key="index">{{it}}</div>
            </div>
          </template>

          <template slot="group_name" slot-scope="text,record">
            <div>
              <div v-for="(it,index) in record.group_name" :key="index">{{it}}</div>
            </div>
          </template>

          <template slot="more" slot-scope="text, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="canUpdate" key="1" @click="showEditModal(record)">
                  信息编辑
                </a-menu-item>
                <a-menu-item key="2" @click="showPasswordModal(record)">
                  重置密码
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>

          <template slot="employee_face" slot-scope="text,record">
            <a href="javascript:;" v-if="record.employee_face==0" style="color:#FCC955;" @click="showFaceModal(record)">未采集</a>
            <a href="javascript:;" v-if="record.employee_face==1" @click="showFaceModal(record)">采集完成</a>
          </template>

          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="showEditModal(record)">编辑</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <faceModal v-if="isFaceModal" :item="modalData"/>
    <editModal v-if="editVisible" :item="modalData" :staffList="staffList"/>
    <transfer v-if="isTransfer" :item="modalData"/>
    <passwordModal v-if="passwordVisible" :item="modalData" isEmployee='2' />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'40px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '', width: '35px', dataIndex: 'employee_avatar', key: 'employee_avatar',scopedSlots: { customRender: 'image' }, },
  { title: '员工姓名', width: 120, dataIndex: 'employee_name', key: 'name' , scopedSlots: { customRender: 'employee_name' }},
  { title: '',width: 30, dataIndex: 'employee_wechat', key: 'employee_wechat' , scopedSlots: { customRender: 'wechat' }},
  { title: '性别', width: 74, dataIndex: 'employee_gender', key: 'age',align:'center'},
  { title: '职称', width: 74, dataIndex: 'position_name', key: 'position_name', ellipsis:true},
  { title: '工号', width: 74, dataIndex: 'job_number', key: 'job_number', ellipsis:true},
  { title: '手机号码', width: 120, dataIndex: 'employee_cellphone', key: '1' , ellipsis:true},
  { title: '所属部门', dataIndex: 'department', key: 'department' , scopedSlots: { customRender: 'department' }, ellipsis:true},
  { title: '直属上级', dataIndex: 'parent_name', key: 'parent_name' },
  { title: '账户角色', dataIndex: 'group_name', key: 'group_name' , scopedSlots: { customRender: 'group_name' }, ellipsis:true},
  { title: '兼/全职', width: 90, dataIndex: 'employee_is_parttime', key: '5' ,align:'center'},
  { title: '人脸采集', width: '80px',scopedSlots: { customRender: 'employee_face' }, key: 'employee_face',align:'center'},
  { title: '账户状态', dataIndex: 'employee_status', key: '11', width: 80,fixed: 'right', scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation',fixed: 'right', scopedSlots: { customRender: 'more' }},
]
const eventsList = [
        { name: 'tree:mounted', args: ['Tree Component'] },
        { name: 'tree:filtered', args: ['Matches', 'Filter String'] },
        { name: 'tree:data:fetch', args: ['Parent Node'] },
        { name: 'tree:data:received', args: ['Parent Node'] },
        { name: 'node:disabled', args: ['Node']},
        { name: 'node:enabled', args: ['Node']},
        { name: 'node:shown', args: ['Node'] },
        { name: 'node:hidden', args: ['Node'] },
        { name: 'node:dblclick', args: ['Node'] },
        { name: 'node:selected', args: ['Node'] },
        { name: 'node:unselected', args: ['Node'] },
        { name: 'node:checked', args: ['Node', 'Target Node'] },
        { name: 'node:unchecked', args: ['Node'] },
        { name: 'node:expanded', args: ['Node'] },
        { name: 'node:collapsed',  args: ['Node'] },
        { name: 'node:added',  args: ['Node', 'New Node'] },
        { name: 'node:removed',  args: ['Node'] },
        { name: 'node:text:changed', args: ['Node', 'New Text', 'Old Text']},
        { name: 'node:editing:start',  args: ['Node'] },
        { name: 'node:editing:stop',  args: ['Node', 'isTextChanged'] },
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import transfer from './transfer'
import ranges from "@/common/mixins/ranges"
import faceModal from './faceModal.vue'
import passwordModal from '@/views/layout/passwordModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import cItems from '@/utils/items'
import moment from 'moment'
import { timeHandle } from '@/utils/timeFormat'


export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    faceModal,
    transfer,
    passwordModal
  },

  data() {
    return {
      loading: false,
      loadingP: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      searchDatas:{add:[]},
      selectedRowKeys:[],
      list: [],
      current: 1,
      studios: [],
      searchData: {},
      visible: false,
      modalData: {},
      isTransfer: false,
      editVisible: false,
      passwordVisible:false,
      isFaceModal:false,
      replaceFields:{
        key: 'id',
        children:'_child',
        title:'name'
      },
      selectedKeys: [],
      events: [],
      treeData:[],
      roles:[],
      treeOptions:{
          propertyNames: {
              'text': 'department_name',
              'id':'department_id',
              "pid":'pid',
              'count':'count'
          },
          dnd: {
            onDragStart(node) {
                return node.data.isNotDraggable !== true
            },
            onDragOn(targetNode, destinationNode, dropPosition) {
                return destinationNode.data.isNotDroppable !== true
            },
            onDragFinish:this.onDragFinish
          },
      },
      reFresh:false,
      firstIn:1,
      authType:['orginization','employee'],
      agreementName:'总部',
      SHOW_PARENT,
      sexs: cItems.sexs,
      staffList:[],
      positionList:[],
    }
  },
  mixins: [ authority,tableMixins ,ranges ],
  computed: {
        eventsList() {
            return this.events.concat().reverse()
        }
  },
  created() {
    this.getPartment()
    this.getRoles()
    this.getStaff()
    this.getPosition()
  },
  updated(){
      if(this.reFresh){
        if(this.firstIn == 1){
          eventsList.forEach(e => {
              this.$refs.sourceTree.$on(e.name, this.initEventViewer(e))
          })
          this.firstIn++
        }
      }
  },
  methods: {
    moment,
    timeHandle,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getRoles(){
      let res = await this.$store.dispatch('employeeAccountRolesAction',{})
      this.roles = res.data
    },
    async getStaff(id){
      let obj = {q:id}
      let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
      this.staffList = res.data
    },
    async getPosition(){
      let res = await this.$store.dispatch('searchPositionAction', {})
      this.positionList = res.data
    },
    async getPartment() {
      this.loadingP = true
      let res = await this.$store.dispatch('employeeOrganizationAction', {})
      this.loadingP = false
      this.treeData = res.data
      this.treeData.forEach((item)=>{
        item.state = {expanded:true}
      })
      res.data.forEach((item)=>{
        item.data.isNotDroppable = true
        item.data.isNotDraggable = true
      })
      if( !this.reFresh ){
        this.reFresh = true;
      }
    },

    async selectTree(selectedKeys,name) {
      this.searchParams.search.department = selectedKeys
      this.agreementName = name
      this.selectedRowKeys = []
      this.searchParams.page=1;
      this.getList()
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = Object.assign(this.searchParams.search, obj)
      let res = await this.$store.dispatch('employeeAccountAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    onSelectChange(selectedRowKeys,record){
      this.selectedRowKeys = selectedRowKeys
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    showFaceModal(item){
      this.modalData = item
      this.isFaceModal = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
        this.getPartment()
      }
      this.editVisible = false
    },

    handleTime(val,time,name){
        this.searchDatas[name] = val
        this.$forceUpdate()
        let start_date = name + '_start_date'
        let end_date = name + '_end_date'
        this.searchParams.search[start_date] = time[0]
        this.searchParams.search[end_date] = time[1]
    },
    changeStatus(record) {
      if (record.employee_status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要禁用"${item.employee_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('employeeAccountDeleteAction', { data: { employee_id: item.employee_id } })
            if (res) {
              that.getList()
              that.getPartment()
              that.$message.success('操作成功!')
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },

    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要恢复"${item.employee_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('employeeAccountRecoveryAction', { data: { employee_id: item.employee_id } })
            if (res) {
              that.getList()
              that.getPartment()
              that.$message.success('操作成功!')
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },

    editNode(node, e) {
      node.startEditing()
    },
    removeNode(node) {
        let that = this
        let obj = {department_id: node.id }
        this.$confirm({
            title: `您确定删除该部门？`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
            return new Promise(async (resolve, reject) => {
                let res = await that.$store.dispatch('employeeOrganizationDelAction', obj)
                resolve(res)
                if(res.code == 200){
                    that.$message.success('操作成功!')
                    node.remove()
                }else{
                    that.$message.error('操作失败!')
                }
            }).catch(error => console.log(error))
            }
        })
    },
    async addChildNode(node) {
        if (node.enabled()) {
            let obj= {
            department_id:node.id,
            department_name:'新增部门'
            }
            let res = await this.$store.dispatch('employeeOrganizationSaveAction', obj)
            if(res.code == 200){
                node.append('新增部门')
                var arr = node.children;
                node.children[arr.length - 1].id = res.data.department_id;
                node.children[arr.length - 1].data.department_count = 0
                this.$message.success('操作成功!')
            }
        }
    },
    async toEditing(node){
        let obj= {
            department_id:node.id,
            department_name:node.text
        }
        let res = await this.$store.dispatch('employeeOrganizationUpdateAction', obj)
        if(res.code == 200){
            this.$message.success('操作成功!')
        }
    },
    initEventViewer(event) {
        var that = this;
        const events = this.events
        return function (node, newNode) {
          if(event.name == 'node:selected'){
            that.selectTree(node.id,node.text)
          }
          if(event.name == 'node:editing:stop'){
              that.toEditing(node);
          }

        }
    },
    onDragFinish(targetNode, destinationNode, dropPosition) {
        if(dropPosition == 'drag-on'){
          let obj = {
            department_id:targetNode.id,
            parent_id:destinationNode.id
          }
          let res = this.$store.dispatch('employeeOrganizationMoveAction', obj)
        }else{
          if(destinationNode.parent){
            let obj = {
              department_id:targetNode.id,
              parent_id:destinationNode.parent.id
            }
            let res = this.$store.dispatch('employeeOrganizationMoveAction', obj)
          }
        }
        return destinationNode.data.isNotDroppable !== true
    },
    showPasswordModal(val) {
      this.modalData = val
      this.passwordVisible = true
    },
    hidePasswordModal(type) {
      this.passwordVisible = false
    },
    showTransfer(){
      this.modalData = this.selectedRowKeys
      this.isTransfer = true
    },
    hideTransfer(val){
      if(val == 1){
        this.getList()
        this.getPartment()
        this.selectedRowKeys = []
      }
      this.isTransfer = false
    },
    handleSearch(val){
      this.getStaff(val)
    }
  },
}
</script>
<style lang="scss">
  .commonTree{
    .node-container{
            display: flex;
        }
        .node-text{
            margin-right: 10px;
        }
        .node-controls{
          display: flex;
          align-items:  center;
          justify-content: center;
            i{
                margin-right: 10px;
            }
        } 
  }
  .followTreeSelect{
    ul{
      .ant-select-tree-checkbox{
        display: none;
      }
      .ant-select-tree-title {
        color: rgba(0, 0, 0, 0.65) !important;
      }
      ul{
        .ant-select-tree-checkbox{
          display: inline-block;
        }
      }
    }
  }
</style>