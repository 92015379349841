<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-form-item :label="item.label" v-for="(item, index) of formData" :key="index">
            <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'position_name',
        label: '职位名称',
        type: 'input',
        rules: [{ required: true, message: '请输入职位名称!' }]
    },
]
    export default {
        name:"editModal",
        inject: ['parent'],
        data() {
            return {
                visible: false,
                loading: false,
                confirmLoading: false,
                ModalTitle: '',
                formData,
                form: this.$form.createForm(this),
            }
        },
        props: {
            item: Object
        },
        async created () {
            this.visible = true
            await this.$nextTick()
            if(this.item){
                this.form.setFieldsValue({position_name:this.item.position_name})
            }
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                if(this.item){
                    params.position_id = this.item.position_id
                }
                this.confirmLoading = true
                this.$store.dispatch(this.item?'employeePositionUpdateAction':'employeePositionAddAction',{data:params})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>