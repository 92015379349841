<template>
    <div>
        <div>
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="plus" type="primary" @click="showEditModal()">新增职位</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search"></div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="position_id"
                :selection="{key: 'employee_id'}" :columns="columns" :dataSource="list">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showEditModal(record)">编辑</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="todel(record)">删除</a>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <editModal v-if="isEditModal" :item="modalData" />
    </div>
</template>

<script>
const columns = [
  { title: '#', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '职位名称', dataIndex: 'position_name', key: 'position_name'},
  { title: '操作', key: 'operation',fixed: 'right', scopedSlots: { customRender: 'action' }},
]
    import tableMixins from '@/common/mixins/table'
    import editModal from './editModal'
    export default {
        name:'position',
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                columns,
                list: [],
                loading:false,
                isEditModal:false,
                modalData:{}
            }
        },
        components: {
            editModal,
        },
        mixins: [ tableMixins ],
        methods: {
            async getList() {
                this.loading = true
                await this.$store.dispatch('employeePositionAction',{data:this.searchParams})
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            async todel(item){
                let that = this
                this.$confirm({
                    title: `您确定要删除该职位？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        that.$store.dispatch('employeePositionDeleteAction', { data: { position_id: item.position_id } })
                        .then(res=>{
                            that.getList()
                            resolve(res)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>