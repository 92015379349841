<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="确认修改" width="32.5%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-if="isEmployee == '1'" v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" type="password" v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
      <a-form-item v-if="isEmployee == '2'" v-for="(item, index) of formData2" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" type="password" v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'currentPassword',
    label: '当前密码',
    type: 'input',
    rules: [{ required: true, message: '请输入当前密码!' }]
  },
  {
    name: 'newPassword',
    label: '新密码',
    type: 'input',
    rules: [{ required: true, message: '请输入新密码!' }]
  },
  {
    name: 'newPasswordConfirm',
    label: '新密码确认',
    type: 'input',
    rules: [{ required: true, message: '请输入新密码确认!' }]
  },
]
const formData2 = [
  {
    name: 'newPassword',
    label: '新密码',
    type: 'input',
    rules: [{ required: true, message: '请输入新密码!' }]
  },
  {
    name: 'newPasswordConfirm',
    label: '新密码确认',
    type: 'input',
    rules: [{ required: true, message: '请输入新密码确认!' }]
  },
]
import C_ITEMS from '@/utils/items'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEmployee:String
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '修改密码',
      visible: false,
      confirmLoading: false,
      formData,
      formData2,
      form: this.$form.createForm(this),
    }
  },

  async created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          let res
          if(this.isEmployee == '2'){
            this.confirmLoading=true
            params.employee_id = this.item.employee_id
            await this.$store.dispatch('employeeAccountResetPasswordAction', { data: params }).then(res=>{
              this.$message.success('操作成功!')
              form.resetFields()
              this.parent.hidePasswordModal(1)
            }).catch(err=>{
              this.confirmLoading=false
            })
          }else{
            this.confirmLoading=true
            await this.$store.dispatch('accoutChangePasswordAction', { data: params }).then(res=>{
              this.$message.success('操作成功!')
              form.resetFields()
              this.parent.hidePasswordModal(1)
            }).catch(err=>{
              this.confirmLoading=false
            })
          }
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hidePasswordModal(0)
    },
    
  }
}
</script>
