<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>员工管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">员工管理<a-badge :count="0" /></span>
          <yggl v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">员工职称<a-badge :count="0" /></span>
          <position v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">结构图谱<a-badge :count="0" /></span>
          <departmentTree v-if="activeKey==='3'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import yggl from './yggl/index'
import position from './position/index'
import departmentTree from './departmentTree/index'

export default {
  components: {
    yggl,
    position,
    departmentTree,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

