<template>
   <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-tree 
              :data="chartData"
              height="950px"
              judge-width
              :settings="{
                expandAndCollapse: true,
                seriesMap: {
                  tree1: {
                    // orient: 'vertical',
                    edgeShape: 'polyline',
                    initialTreeDepth: 7,
                    lineStyle: {
                      width: 3
                    },
                    label: {
                      backgroundColor: '#fff',
                      position: 'left',
                      verticalAlign: 'middle',
                      align: 'right'
                    },
                    leaves: {
                      label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                      },
                    },
                    emphasis: {
                      focus: 'descendant'
                    },
                    itemStyle: {
                      color: '#00cca2',
                      borderColor: '#00cca2',
                    }
                  }
                }
              }"

            ></ve-tree>
            </div>
    </a-card>
</template>

<script>
  export default {
    name:'veTrees',
    data () {
      return {
        chartData: {
          columns: ['name', 'value'],
          rows: [
            {
              name: 'tree1',
              value: []
            }
          ]
        }
      }
    },
    props:['leads_data' , 'loading' , 'title'],
    watch: {
      leads_data(newValue, oldValue) {
        this.chartData.rows[0].value = [this.leads_data]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>