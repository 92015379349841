<template>
        <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" centered okText="转移" width="400px"
            @ok="handleOk" @cancel="handleCancel">
            <a-form layout="inline">
                <a-form-item label="变更部门为：">
                    <a-cascader  :show-search="{ filterOption }" v-model="agreement_id" :field-names='fieldNames' placeholder="请选择" :options="DepOptions" change-on-select>
                        <template slot="displayRender" slot-scope="{ labels }">
                            <span>{{labels[labels.length - 1]}}</span>
                        </template>
                    </a-cascader>
                </a-form-item>
            </a-form>
        </a-modal>
</template>

<script>
    export default {
        name:'transfer',
        inject: ['parent'],
        props:['item'],
        data() {
            return {
                visible:false,
                ModalTitle: '批量转移',
                confirmLoading:false,
                consultants:[],
                course_consultant_id:undefined,
                fieldNames:{
                    label:'department_name',
                    value:'department_id',
                    children:'children'
                },
                DepOptions:[],
                agreement_id:[],
            }
        },
        created () {
            this.visible = true
            this.getDepartment()
        },
        beforeDestroy () {
            this.visible = false;
        },
        methods: {
            filterOption(input, option) {
                return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
            },
            async getDepartment(){
                let res = await this.$store.dispatch('employeeOrganizationAction', {})
                this.DepOptions = res.data
            },
            async handleOk() {
                if(this.agreement_id.length == 0){
                    this.$message.warning('请选择部门!!')
                    return false
                }
                this.confirmLoading = true
                let agreement_id = this.agreement_id[this.agreement_id.length -1]
                await this.$store.dispatch('employeeAccountMoveToAction', {data:{department:agreement_id,employee_id:this.item}})
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideTransfer(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                    this.confirmLoading = false
                })
                
                
            },
            handleCancel(){
                this.parent.hideTransfer(0)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>