<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
      <a-row type="flex" align="top">
      <a-col :span="item.name == 'remark'?24:12" v-for="(item, index) of formData" :key="index" >
          <a-form-item :label="item.label" :label-col="item.name == 'remark'?labelColF:labelCol" :wrapper-col="item.name == 'remark'?wrapperColF:wrapperCol">
            <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
            <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
              <div v-if="imageUrl">
                <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
                <div class="close" @click="closeImg($event)">&times;</div>
              </div>
              <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
            </div>
            <div v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
              <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
              <div style="display: flex; flex-wrap: wrap;">
                <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
                  <LImg :src="img" style="width: 100px; height: 100px;"/>
                  <div class="close" @click="closeImg2($event, index)">&times;</div>
                </div>
              </div>
            </div>
            <a-switch :checked="formal_employee == 1" @change="handleSwitch" v-if="item.type === 'switch'" />
            <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
            <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
              <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
            </a-radio-group >

            <!-- <a-cascader v-else-if="item.type === 'cascader'" v-decorator="[item.name, { rules: item.rules }]" @change="onChange" :field-names='fieldNames' placeholder="请选择" :options="DepOptions" change-on-select >
              <template slot="displayRender" slot-scope="{ labels }">
                <span>{{labels[labels.length - 1]}}</span>
              </template>
            </a-cascader> -->

            <a-tree-select
              v-else-if="item.type === 'cascader'"
              v-decorator="[item.name, { rules: item.rules }]"
              :tree-data="DepOptions"
              tree-checkable
              allowClear
              :replaceFields="{
                title:'department_name',
                value: 'department_id',
              }"
              :maxTagCount='1'
              :maxTagTextLength='5'
              :treeCheckStrictly='true'
              :dropdownStyle="{maxHeight:'400px'}"
              :show-checked-strategy="SHOW_ALL"
              placeholder="请选择部门"
            />

            <a-select v-else-if="item.type === 'select2'" mode="multiple" placeholder="请选择" allowClear showSearch :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]">
              <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
            </a-select>
            <a-select v-else-if="item.type === 'selects'" placeholder="请选择" showSearch allowClear :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]">
              <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
            </a-select>

            <a-select v-else-if="item.type === 'select'" placeholder="请选择" showSearch allowClear :filterOption="filterOption" v-decorator="[item.name, { rules: item.rules }]">
              <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
            </a-select>

            <!-- <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" allowClear>
                <a-select-option v-for="(item,index) in seleteItems[item.items.data]" :key="index"  :value="item.employee_id">{{item.filter_name}}</a-select-option>
            </a-select> -->
            <a-tree-select
              v-else-if="item.type === 'treeSelect'"
              v-decorator="[item.name, { rules: item.rules }]"
              style="width: 100%"
              dropdownClassName='followTreeSelect'
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              tree-checkable
              :maxTagCount='1'
              :maxTagTextLength='5'
              treeNodeFilterProp="title"
              :show-checked-strategy="SHOW_PARENT"
              search-placeholder="Please select">
                <a-tree-select-node v-for="(d, index) of seleteItems[item.items.data]" :disabled="true" :key="d.type_id" :value="d.type_id" :title="d.name">
                  <a-tree-select-node v-for="(dc, ind) of d.children" :key="dc.value" :value="dc.value" :title="dc.title"></a-tree-select-node>
                </a-tree-select-node>
              </a-tree-select>
            <a-textarea v-else-if="item.type === 'textarea'" :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>
          </a-form-item>
      </a-col>
    </a-row>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [];
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import moment from 'moment'
import createFilePath from '@/utils/tools'
import ImageModal from '@/views/modal/images'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
const SHOW_ALL = TreeSelect.SHOW_ALL
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    staffList: Array,
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 15 },
      labelColF: { span: 4 },
      wrapperColF: { span: 20 },
      ModalTitle: '新增员工',
      loading: false,
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        sexs: C_ITEMS.sexs,
        classCategorys: [],
        roles: [],
        parts:[
          {label:'全职',value:0},
          {label:'兼职',value:1}
        ]
      },
      imageRoot: url.imageRoot,
      imageUrl: '',
      imageUrls: [],
      imagesVisible: false,
      imageKey: '',
      DepOptions:[],
      fieldNames:{
        label:'department_name',
        value:'department_id',
        children:'children'
      },
      cascaderValue:[],
      // staffList:[],
      detail: {},
      valueTree:[],
      SHOW_PARENT,
      SHOW_ALL,
      formal_employee:0,
    }
  },

  async created() {
    this.visible = true
    this.getStaff()
    this.getRoles()
    this.getPosition()
    this.getDepartment()
    await this.$nextTick()
    if (this.item) {
      this.formData=[
        {
          name: 'image_avatar',
          label: '员工头像',
          type: 'upload',
          rules: [{ required: true, message: '请上传员工头像!' }]
        },
        {
          name: 'realname',
          label: '员工名字',
          type: 'input',
          rules: [{ required: true, message: '请输入员工名字!' }]
        },
        {
          name: 'gender',
          label: '员工性别',
          type: 'radio',
          rules: [{ required: true, message: '请选择员工性别!' }],
          items: {
            data: 'sexs',
            label: 'label',
            value: 'value'
          }
        },
        {
          name: 'job_number',
          label:  '员工工号',
          type: 'input',
          rules: [{ required: false, message: '请输入员工工号!' }]
        },
        {
          name: 'id_number',
          label: '身份证号',
          type: 'input',
          rules: [{ required: false, message: '请输入身份证号!' }]
        },
        {
          name: 'telphone',
          label: '手机号码',
          type: 'input',
          rules: [{ required: true, message: '请输入手机号码!' }]
        },
        {
          name: 'entry_time',
          label: '入职时间',
          type: 'date',
          rules: []
        },
        {
          name: 'departure_time',
          label: '离职时间',
          type: 'date',
          rules: []
        },
        {
          name: 'department',
          label: '所属部门',
          type: 'cascader',
          rules: [{ required: true, message: '请选择部门!' }],
          items: {
            data: 'classCategorys',
            label: 'studio_name',
            value: 'studio_id'
          }
        },
        {
          name: 'parent_id',
          label: '直属上级',
          type: 'select',
          rules: [],
          items: {
            data: 'staffList',
            label: 'filter_name',
            value: 'employee_id'
          }
        },
        {
          name: 'group_id',
          label: '账户角色',
          type: 'treeSelect',
          rules: [{ required: true, message: '请选择账户角色!' }],
          items: {
            data: 'roles',
            label: 'filter_name',
            value: 'role_id'
          }
        },
        {
          name: 'position_id',
          label: '员工职位',
          type: 'selects',
          rules: [],
          items: {
            data: 'positionList',
            label: 'filter_name',
            value: 'position_id'
          }
        },
        {
          name: 'formal_employee',
          label: '正式员工',
          type: 'switch',
          rules: []
        },
        {
          name: 'part_time',
          label: '兼职/全职',
          type: 'radio',
          rules: [],
          items:{
            data: 'parts',
            label: 'label',
            value: 'value'
          },
        },
        {
          name: 'remark',
          label: '员工简介',
          type: 'textarea',
          rules: []
        }
        // {
        //   name: 'images',
        //   label: '个人图片(多图)',
        //   type: 'upload2',
        //   rules: []
        // },
      ];

      this.ModalTitle = '编辑'
      let item = await this.getDetail(this.item.employee_id)
      let formFields = {}
      for (let d of this.formData) {
        if (d.name === 'studio') {
          let arr = []
          for (let s of item[d.name]) {
            arr.push(parseInt(s))
          }
          item[d.name] = arr
        }
        if (d.name === 'role') {
          let arr = []
          for (let k in item[d.name]) {
            arr.push(k)
          }
          item[d.name] = arr
        }
        if (d.name === 'tags') {
          item[d.name] = item[d.name].join(',')
        }
        if (d.name === 'department') {
          item[d.name] = item.department_name
        }
        if (d.name === 'entry_time' || d.name === 'departure_time') {
          if(item[d.name]){
            item[d.name] = moment(item[d.name])
          }else{
            delete item[d.name]
          }
        }
        if (['image_avatar', 'images'].indexOf(d.name) === -1) {
          formFields[d.name] = item[d.name] || ''
        }
        formFields[d.name] = item[d.name]
      }
      this.imageUrl = item.image_avatar
      this.imageUrls = item.images
      this.formal_employee = item.formal_employee
      this.form.setFieldsValue(formFields)
      delete formFields.department_id
      delete formFields.image_avatar
      delete formFields.formal_employee
      delete formFields.images 
      this.form.setFieldsValue(formFields)
    }else{
      
      this.formData=[
        {
          name: 'image_avatar',
          label: '员工头像',
          type: 'upload',
          rules: [{ required: true, message: '请上传员工头像!' }]
        },
        {
          name: 'realname',
          label: '员工名字',
          type: 'input',
          rules: [{ required: true, message: '请输入员工名字!' }]
        },
        {
          name: 'gender',
          label: '员工性别',
          type: 'radio',
          rules: [{ required: true, message: '请选择员工性别!' }],
          items: {
            data: 'sexs',
            label: 'label',
            value: 'value'
          }
        },
        {
          name: 'job_number',
          label:  '员工工号',
          type: 'input',
          rules: [{ required: false, message: '请输入员工工号!' }]
        },
        {
          name: 'id_number',
          label: '身份证号',
          type: 'input',
          rules: [{ required: false, message: '请输入身份证号!' }]
        },
        {
          name: 'telphone',
          label: '手机号码',
          type: 'input',
          rules: [{ required: true, message: '请输入手机号码!' }]
        },
        {
          name: 'entry_time',
          label: '入职时间',
          type: 'date',
          rules: []
        },
        {
          name: 'departure_time',
          label: '离职时间',
          type: 'date',
          rules: []
        },
        {
          name: 'department',
          label: '所属部门',
          type: 'cascader',
          rules: [{ required: true, message: '请选择部门!' }],
          items: {
            data: 'classCategorys',
            label: 'studio_name',
            value: 'studio_id'
          }
        },
        {
          name: 'parent_id',
          label: '直属上级',
          type: 'select',
          rules: [],
          items: {
            data: 'staffList',
            label: 'filter_name',
            value: 'employee_id'
          }
        },
        {
          name: 'group_id',
          label: '账户角色',
          type: 'treeSelect',
          rules: [{ required: true, message: '请选择账户角色!' }],
          items: {
            data: 'roles',
            label: 'filter_name',
            value: 'role_id'
          }
        },
        {
          name: 'position_id',
          label: '员工职位',
          type: 'selects',
          rules: [],
          items: {
            data: 'positionList',
            label: 'filter_name',
            value: 'position_id'
          }
        },
        {
          name: 'formal_employee',
          label: '正式员工',
          type: 'switch',
          rules: []
        },
        {
          name: 'part_time',
          label: '兼职/全职',
          type: 'radio',
          rules: [],
          items:{
            data: 'parts',
            label: 'label',
            value: 'value'
          },
        },
        {
          name: 'password',
          label: '登陆密码',
          type: 'input',
          rules: [{ required: true, message: '请输入登陆密码!' }]
        },
        {
          name: 'remark',
          label: '员工简介',
          type: 'textarea',
          rules: []
        }
        // {
        //   name: 'images',
        //   label: '个人图片(多图)',
        //   type: 'upload2',
        //   rules: []
        // },
      ];
      this.form.setFieldsValue({part_time:0})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'images') {
          this.imageUrls.push(image.path)
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
    async getDetail(employee_id) {
      this.loading = true
      let res = await this.$store.dispatch('employeeAccountDetailAction', {data: {employee_id}})
      // res.data.parent_id = res.data.parent_id.toString()
      this.detail = res.data
      this.cascaderValue = res.data.department_id
      this.loading = false
      return res.data
    },

    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.employee_id = item.employee_id
          }
          if (params.tags) {
            params.tags = params.tags.split(',')
          }
          let department = []
          if(params.department.length != 0){
            params.department.forEach(item=>{
              department.push(item.value)
            })
            params.department = department
          }
          if(params.entry_time){
            params.entry_time = moment(params.entry_time).format("YYYY-MM-DD")
          }else{
            delete params.entry_time
          }
          if(params.departure_time){
            params.departure_time = moment(params.departure_time).format("YYYY-MM-DD")
          }else{
            delete params.departure_time
          }
          params.image_avatar = this.imageUrl
          params.images = this.imageUrls
          params.formal_employee = this.formal_employee
          this.confirmLoading=true
          await this.$store.dispatch(item ? 'employeeAccountUpdateAction' : 'employeeAccountAddAction', { data: params })
            .then(res=>{
              form.resetFields()
              this.parent.hideEditModal(1)
              this.$message.success('操作成功!')
            })
            .catch(err=>{
              this.confirmLoading=false;
            })
        }
      } catch {
      }
    },
    async getDepartment(){
      this.loading = true
      let res = await this.$store.dispatch('employeeOrganizationAction', {})
      this.DepOptions = res.data
      this.loading =false;
    },
    onChange(val){
      this.cascaderValue = val
      this.getStaff(null,1)
    },
    async getStaff(id,num){
      // this.loading = true
      // let obj = {};
      // if(num === 1){
      //   obj = {department:this.cascaderValue[this.cascaderValue.length - 1]}
      // }else{
      //   obj = {q:id}
      // }
      // let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
      // this.staffList = res.data;
      this.seleteItems.staffList = this.staffList
      // this.loadiing =false;
    },
    async getPosition(){
      this.loading = true
      let res = await this.$store.dispatch('searchPositionAction', {})
      this.seleteItems.positionList = res.data
      this.loading =false;
    },
    async getRoles(){
      this.loading = true
      let res = await this.$store.dispatch('employeeAccountRolesAction',{})
      this.seleteItems.roles = res.data;
      this.loading =false;
    },
    handleSearch(val){
      this.getStaff(val,2)
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    handleSwitch(e){
      if(e){
        this.formal_employee = 1
      }else{
        this.formal_employee = 0
      }
    }
    
    // showImagesModal(imageKey) {
    //   this.imageKey = imageKey
    //   this.imagesVisible = true
    // },
    // hideImagesModal(image) {
    //   if (image) {
    //     console.log(image)
    //     this.imageUrl = image.path
    //     this.imagePath = image.path
    //   }
    //   this.imagesVisible = false
    // },
  }
}
</script>
<style lang="scss">
  
</style>
